<!--  -->
<template>
  <div class="top">
    <div class="content">
      <header class="header-box">
        <span>Lists</span>
        <div class="create-bt">
          <el-button size="mini" style="color: #006dcc" @click="remindSwicth"
            >Import</el-button
          >
          <el-button type="primary" size="mini" @click="routeTo"
            >Create list</el-button
          >
        </div>
        <div class="remind-box" v-show="remindFlg">
          <span class="el-icon-warning"></span>
          <div class="remind-title">
            Your export “HubSpot exam - Contacts impo”has been delivered.<a
              >Click here to download.</a
            >
          </div>
          <i class="el-icon-close" @click="remindSwicth"></i>
        </div>
      </header>
      <!-- 切换 -->
      <div class="main-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="contactLabel" name="contactLabel"></el-tab-pane>
          <el-tab-pane :label="companyLabel" name="companyLabel"></el-tab-pane>
          <el-tab-pane :label="unuserLabel" name="unuserLabel"></el-tab-pane>
        </el-tabs>
        <contactLists v-show="contactListsFlg"></contactLists>
        <companyLists v-show="companyListsFlg"></companyLists>
        <unuserLists
          v-show="unuserListsFlg"
          :unuserNumber="unuserNumber"
        ></unuserLists>
        <mandatory v-show="mandatoryFlg"></mandatory>
      </div>
    </div>
  </div>
</template>

<script>
import companyLists from '@/views/lists/lists/companyLists.vue'
import contactLists from '@/views/lists/lists/contactLists.vue'
import unuserLists from '@/views/lists/lists/unuserLists.vue'
import mandatory from '@/views/lists/lists/mandatory.vue'
export default {
  components: {
    mandatory,
    contactLists,
    companyLists,
    unuserLists
  },
  data() {
    return {
      remindFlg: false,
      contactNumber: 12,
      companyLabel: '',
      companyNumber: 0,
      contactLabel: '',
      unuserNumber: 0,
      unuserLabel: '',
      activeName: 'contactLabel',
      mandatoryFlg: false,
      contactListsFlg: true,
      companyListsFlg: false,
      unuserListsFlg: false,
      clearFlg: true,
      action: {
        Search: '',
        creatorsSearch: '',
        clearFlg: true
      },
      tapFlg: false,
      socisltitle: ['Contact lists library (12)', 'Company lists library (0)', 'Unused Contact lists (0)'],
    };
  },
  created() {
    // 标题及数据长度
    this.contactLabel = `Contact lists library (${this.contactNumber})`;
    this.companyLabel = `Company lists library (${this.companyNumber})`;
    this.unuserLabel = `Unused Contact lists (${this.unuserNumber})`;
  },
  beforeRouteEnter(to, from, next) {
    
    next(vm => {
      if (from.fullPath === "/lists/createLists") {
        vm.activeName = 'companyLabel';
        if (vm.companyNumber == 0) {
          vm.mandatoryFlg = true;
          vm.contactListsFlg = false;
          vm.companyListsFlg = false;
          vm.unuserListsFlg = false;
        } else {
          vm.mandatoryFlg = false;
          vm.contactListsFlg = false;
          vm.companyListsFlg = true;
          vm.unuserListsFlg = false;
        }
      }
    });
  },
  //方法集合
  methods: {
    remindSwicth() {
      this.remindFlg = !this.remindFlg
    },
    // 切换，当数据长度为0 显示无数据页
    handleClick(tab) {
      if (tab.index === "0" && this.contactNumber !== 0) {
        this.contactListsFlg = true;
        this.companyListsFlg = false;
        this.unuserListsFlg = false;
        this.mandatoryFlg = false
      } else if (tab.index === "1" && this.companyNumber !== 0) {
        this.contactListsFlg = false
        this.companyListsFlg = true
        this.unuserListsFlg = false
        this.mandatoryFlg = false
      } else if (tab.index === "2") {
        this.contactListsFlg = false
        this.companyListsFlg = false
        this.unuserListsFlg = true
        this.mandatoryFlg = false
      } else {
        this.contactListsFlg = false
        this.companyListsFlg = false
        this.unuserListsFlg = false
        this.mandatoryFlg = true
      }
    },
    swicth() {
      this.tapFlg = !this.tapFlg
    },
    routeTo() {
      this.$router.push({
        name: "createLists",
        params: {

        },
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.top {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px;
}
.content {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 15px 8px 40px;
  .header-box {
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #080707;
    height: 12%;
    position: relative;
    .remind-box {
      position: absolute;
      background: #c8c6c6;
      border-radius: 3px;
      border-radius: 3px;
      padding: 10px 10px 10px 30px;
      display: flex;
      align-items: center;
      left: 25%;
      right: 16%;
      span {
        color: #ffffff;
        font-size: 35px;
        margin-right: 15px;
      }
      i {
        cursor: pointer;
        margin: 0px 30px;
        color: rgba(255, 255, 255, 0.5);
      }
      .remind-title {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #ffffff;
        display: inline-block;
      }
    }
  }
  .main-content {
    height: 80%;
  }
}
.el-tabs {
  margin-top: 15px;
  ::v-deep .el-tabs__item {
    font-size: 16px;
    padding-left: 28px;
    outline: none;
  }
  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #080707;
  }
}
</style>