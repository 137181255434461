<!--  -->
<template>
  <div class="main-content">
    <div class="action-box" v-if="unuserNumber !== 0">
      <el-button-group>
        <el-button size="mini" :type="tapFlg ? 'primary' : ''" @click="swicth"
          >All lists</el-button
        >
        <el-button size="mini" @click="swicth" :type="tapFlg ? '' : 'primary'"
          >Folders
        </el-button>
      </el-button-group>
      <el-input
        size="small"
        suffix-icon="el-icon-search"
        v-model="action.search"
        class="actionInput"
      >
      </el-input>
      <span>Filter by：</span>
      <el-dropdown trigger="click" placement="bottom">
        <span class="el-dropdown-link">
          All Types<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="el-dropdown-main">
          <el-input
            size="small"
            suffix-icon="el-icon-search"
            v-model="action.creatorsSearch"
          >
          </el-input>
          <el-dropdown-item v-for="(item, index) in typesList" :key="index"
            >{{ item.name }} <span>{{ item.email }}</span></el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" placement="bottom">
        <span class="el-dropdown-link">
          All creators<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in creatorsList" :key="index"
            ><p>{{ item }}</p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span class="clearFilter" v-show="clearFlg">Clear filters</span>
    </div>
    <div class="prompt" v-else>
      <div class="prompt-text">
        Keep your library clean of lists that are no longer useful to you and
        your team.
      </div>
      <div class="prompt-text">
        The lists shown here haven`t updated in the last two months. And are
        also not being used in any tools like workfolws or emails.
      </div>
    </div>
    <div class="breadcrumb" v-show="!tapFlg && unuserNumber !== 0">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(item, index) in crumbList" :key="index"
          ><span
            :class="
              index === crumbList.length - 1
                ? 'el-breadcrumb-bold'
                : 'el-breadcrumb'
            "
            >{{ item }}</span
          ></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <listTable
      :tableAttr="tableAttr"
      :tableData="tableData"
      :tableheight="tableheight"
      :selectvalue="selectvalue"
      :buttonlist="buttonlist"
      :pageshow="pageshow"
      :checkDisable="checkDisable"
      @showDrawer="showDrawer"
    ></listTable>
    <el-drawer
      title="List details"
      :visible.sync="drawer"
      :direction="direction"
      size="30%"
    >
      <div class="drawer-title">
        Top Client: <input v-model="headline" v-show="editLineFlg" /><span
          class="main-text"
        >
          {{ editLineFlg ? "" : headline }}</span
        >
        <svg
          class="icon"
          aria-hidden="true"
          style="width: 15px; height: 15px"
          @click="editLine"
        >
          <use href="#icon-editPens"></use>
        </svg>
      </div>
      <div class="drawer-title-min">
        Top Client:<span class="main-text">April 24, 2021 11:19 AM</span>
      </div>
      <div class="drawer-title-min boderBt">
        Top Client:<span class="main-text">yue shao</span>
      </div>
      <div class="drawer-title-min mt-20">Assets using this list</div>
      <div class="content-text">None</div>
    </el-drawer>
  </div>
</template>

<script>
import listTable from '@/views/lists/components/listTable.vue'
export default {
  components: { listTable },
  props: {
    unuserNumber: {
      type: Number,
    }
  },
  data() {
    return {
      headline: 'headline',
      editLineFlg: false,
      //侧栏
      drawer: false,
      direction: 'rtl',
      creatorsList: ['All folders', '123', '2324'],
      typesList: [{ name: 'All Types' }, { name: 'zyt', email: '92321312@qq.com' }],
      //面包屑
      crumbList: ['All folders', 'Demo 0424'],
      clearFlg: true,
      action: {
        Search: '',
        creatorsSearch: '',
        clearFlg: true
      },
      tapFlg: true,
      //表格数据
      checkDisable: true,
      tableAttr: [
        { aa: "NAME", bb: "name", cc: false, dd: true, ee: 'A', width: "300px" },
        { aa: "EMAIL", bb: "email", cc: false, dd: true, ee: 'B', width: "" },
        { aa: "PHONE NUMBER", bb: "phonenumber", cc: false, dd: true, ee: 'D', width: "" },
        { aa: "CREATOR", bb: "creator", cc: false, dd: true, ee: 'B', width: "" },
      ],
      tableheight: '300px',//表格高度
      buttonlist: ["Details", 'More'],
      tableData: [
        {
          name: 'Asddd',
          vshow: false,
          email: "123456@qq.com",
          phonenumber: '-',
          creator: "",
          optionshow: false
        },
        {
          img: '#icon-in',
          name: 'dvgdnhjutm',
          vshow: false,
          email: "ssssss@qq.com",
          phonenumber: '-',
          creator: "",
          optionshow: false
        },
        {
          img: '#icon-in',
          name: 'wwwwww',
          vshow: false,
          email: "09754@qq.com",
          phonenumber: '1359344555455',
          creator: "",
          optionshow: false
        },
        {
          img: '#icon-in',
          name: 'wwwwww',
          vshow: false,
          email: "4568789@qq.com",
          phonenumber: '13034456788',
          creator: "",
          optionshow: false
        },
      ],
      selectvalue: {
        selectvalue1: ["clone", "View list performance", "Delete list"],
        selectvalue2: []
      },
      pageshow: true,
    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    showDrawer() {
      this.drawer = true
    },
    editLine() {
      this.editLineFlg = !this.editLineFlg
    },
    swicth() {
      this.tapFlg = !this.tapFlg
    },
  },
}
</script>
<style lang='scss' scoped>
.prompt {
  height: 65px;
  background: #c8c6c6;
  border-radius: 3px;
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 10px;
  .prompt-text {
    font-family: SourceHanSansCN-Medium;
    font-size: 16px;
    color: #ffffff;
  }
}
::v-deep .el-drawer__header {
  font-family: SourceHanSansCN-Medium;
  font-size: 18px;
  color: #080707;
}
::v-deep .el-drawer__body {
  padding: 30px;
  .content-text {
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    color: #888888;
    width: 100%;
    word-wrap: break-word;
    line-height: 20px;
  }
  .boderBt {
    border-bottom: 1px solid #dddbda;
  }
  .main-text {
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    color: #888888;
    margin-left: 5px;
  }
  .drawer-title-min {
    height: 50px;
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    color: #080707;
  }
  .drawer-title {
    height: 50px;
    font-family: SourceHanSansCN-Medium;
    font-size: 16px;
    color: #080707;
  }
}
.breadcrumb {
  height: 35px;
  .el-breadcrumb-bold {
    display: inline-block;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #888888;
  }
  .el-breadcrumb {
    display: inline-block;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #006dcc;
  }
}
.el-dropdown-main {
  ::v-deep .el-input {
    padding: 10px;
  }
}
::v-deep .el-input__icon {
  margin-right: 10px;
}
::v-deep .el-dropdown-menu__item {
  font-family: SourceHanSansCN-Normal;
  font-size: 12px;
  color: #080707;
  text-align: center;
  line-height: 20px;
  span {
    display: block;
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    color: #888888;
  }
  p {
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    color: #006dcc;
  }
}
.action-box {
  margin: 10px 0px;
  ::v-deep .el-input {
    padding: 10px;
  }
  .clearFilter {
    cursor: pointer;
    color: #409eff;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    margin-left: 10px;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  button {
    height: 32px;
    margin-bottom: 3px;
  }
  .actionInput {
    width: 330px;
    margin-left: 10px;
  }
  span {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    margin-left: 10px;
  }
}
</style>