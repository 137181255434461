<!--
 * @Author: your name
 * @Date: 2021-07-05 16:19:20
 * @LastEditTime: 2021-08-30 13:59:56
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\lists\lists\mandatory.vue
-->
<!-- 数据为空页 -->
<template>
  <div class="top">
    <svg class="icon" style="width:100px;height:120px" aria-hidden="true">
      <use href="#icon-empty-search"></use>
    </svg>
    <div class="createTitle">Create lists to power your business.</div>
    <div class="content-text">
      Use lists segment by industry , size, location , value , or anything else
      you can imagine.
    </div>
    <div class="content-text">
      Want to learn more about lists? Check out our user guide to
      <a>learn more.</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.top {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.createTitle {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  height: 60px;
  line-height: 60px;
}
.content-text {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #888888;
  letter-spacing: 0;
  text-align: center;
  height: 60px;
  line-height: 60px;
}
</style>
